import { onAuthStateChanged, User as FirebaseUser } from "firebase/auth";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { firebaseAuth } from "../../../lib/firebase";
import Loader from "../components/Loader";
import React, {
  Suspense,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  SnsType,
  useGetMyUserLazyQuery,
} from "../../../lib/apollo/graphql/generated";

interface AuthState {
  initializing: boolean;
  firebaseUser: FirebaseUser | null;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    name?: string | null;
    username?: string | null;
    dateOfBirth?: string | null;
    phone?: string | null;
    resignReason?: string | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    snsChannels: Array<{
      __typename?: "SNSChannel";
      id: string;
      type: SnsType;
    } | null>;
    ownedProfiles: Array<{
      __typename?: "Profile";
      id: string;
      business?: {
        __typename?: "Business";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        categories?: Array<{
          __typename?: "Category";
          id: string;
          iconUrl?: string | null;
          names?: Array<{
            __typename?: "CategoryName";
            id: string;
            language?: string | null;
            name?: string | null;
            languageCode?: string | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null>;
    profiles: Array<{
      __typename?: "ProfileUser";
      id: string;
      profile: {
        __typename?: "Profile";
        business?: {
          __typename?: "Business";
          id: string;
          name?: string | null;
          avatar?: { __typename?: "Media"; id: string; uri: string } | null;
          categories?: Array<{
            __typename?: "Category";
            id: string;
            iconUrl?: string | null;
            names?: Array<{
              __typename?: "CategoryName";
              id: string;
              language?: string | null;
              name?: string | null;
              languageCode?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
      };
    } | null>;
  } | null;
}

const defaultState: AuthState = {
  firebaseUser: null,
  initializing: true,
  user: null,
};

const AuthContext = createContext<AuthState>(defaultState);

function AuthProvider() {
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState<AuthState>({ ...defaultState });

  const [getMyUser, { loading, data }] = useGetMyUserLazyQuery({
    fetchPolicy: "network-only",
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, async (firebaseUser) => {
      if (firebaseUser) {
        getMyUser();

        setState((prev) => ({
          ...prev,
          initializing: false,
          firebaseUser,
        }));
      } else {
        setState((prev) => ({
          ...defaultState,
          initializing: false,
        }));
      }
    });
  }, []);

  const memoizedOutlet = useMemo(() => {
    return <Outlet />;
  }, [state]);

  if (state?.initializing) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={{ ...state, user: data?.getMyUser }}>
      <Suspense fallback={<Loader />}>{memoizedOutlet}</Suspense>
    </AuthContext.Provider>
  );
}

export default AuthProvider;

export function useAuthContext() {
  return useContext(AuthContext);
}
