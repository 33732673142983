import { lazy } from "react";
import { createBrowserRouter, Outlet, RouteObject } from "react-router-dom";
import AuthProvider from "../../../modules/common/providers/AuthProvider";
import PrivateRoute from "../components/PrivateRoute";

const LandingContainer = lazy(
  () => import("../../../modules/landing/container/LandingContainer")
);

const SignInContainer = lazy(
  () => import("../../../modules/auth/signIn/container/SignInContainer")
);

const SignUpIntroContainer = lazy(
  () =>
    import(
      "../../../modules/auth/signUp/signUpIntro/container/SignUpIntroContainer"
    )
);

const SignUpEmailContainer = lazy(
  () =>
    import(
      "../../../modules/auth/signUp/signUpEmail/container/SignUpEmailContainer"
    )
);

const ConnectContainer = lazy(
  () => import("../../../modules/connect/container/ConnectContainer")
);

const UploadContainer = lazy(
  () => import("../../../modules/upload/container/UploadContainer")
);

const DashboardContainer = lazy(
  () => import("../../../modules/dashboard/container/DashboardContainer")
);

const UpdateProfileContainer = lazy(
  () =>
    import(
      "../../../modules/profile/updateProfile/container/UpdateProfileContainer"
    )
);

const UpdateBusinessProfileContainer = lazy(
  () =>
    import(
      "../../../modules/profile/updateBusinessProfile/container/UpdateBusinessProfileContainer"
    )
);

const ContactContainer = lazy(
  () =>
    import("../../../modules/contact/contact/container/ContactListContainer")
);

const CampaignEditorContainer = lazy(
  () =>
    import(
      "../../../modules/campaign/campaignEditor/container/CampaignEditorContainer"
    )
);

const CampaignListContainer = lazy(
  () =>
    import(
      "../../../modules/campaign/campaignList/container/CampaignListContainer"
    )
);

export const SCREENS = {
  LANDING: "/",

  SIGN_UP: "/sign_up",

  SIGN_UP_INTRO: "/start",

  SIGN_UP_EMAIL: "/email",

  SIGN_IN: "/sign_in",

  DASHBOARD: "/dashboard",

  FIRST_CONNECT: "/first_connect",

  CONNECT: "/connect",

  RESET_PASSWORD: "/reset_password",

  UPLOAD: "/upload",

  MANAGE_ACCOUNT: "/manage_account",

  UPDATE_BUSINESS: "/update_business",

  CONTACT: "/contact",

  CAMPAIGNS: "/campaigns",

  EDITOR: "/editor",
};

const PRIVATE_CHILDREN: RouteObject[] = [
  {
    path: SCREENS.FIRST_CONNECT,
    element: <ConnectContainer />,
  },

  {
    path: SCREENS.CONNECT,
    element: <ConnectContainer />,
    children: [
      {
        path: SCREENS.CONNECT + "/:sns_type",
        element: <ConnectContainer />,
      },
    ],
  },

  {
    path: SCREENS.DASHBOARD,
    element: <DashboardContainer />,
  },

  {
    path: SCREENS.UPLOAD,
    element: <UploadContainer />,
  },

  {
    path: SCREENS.MANAGE_ACCOUNT,
    element: <UpdateProfileContainer />,
  },
  {
    path: SCREENS.UPDATE_BUSINESS + "/:profile_id",
    element: <UpdateBusinessProfileContainer />,
  },
  {
    path: SCREENS.CONTACT,
    element: <ContactContainer />,
  },
  {
    path: SCREENS.EDITOR + "/:actionId",
    element: <CampaignEditorContainer />,
  },
  { path: SCREENS.CAMPAIGNS, element: <CampaignListContainer /> },
];

export const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    children: [
      {
        path: SCREENS.LANDING,
        element: <LandingContainer />,
      },

      {
        path: SCREENS.SIGN_UP,
        element: <Outlet />,
        children: [
          {
            path: SCREENS.SIGN_UP + SCREENS.SIGN_UP_INTRO,
            element: <SignUpIntroContainer />,
          },

          {
            path: SCREENS.SIGN_UP + SCREENS.SIGN_UP_EMAIL,
            element: <SignUpEmailContainer />,
          },
        ],
      },

      {
        path: SCREENS.SIGN_IN,
        element: <SignInContainer />,
      },

      {
        path: SCREENS.RESET_PASSWORD,
        element: <div />,
      },

      {
        element: <PrivateRoute />,
        children: PRIVATE_CHILDREN,
      },
    ],
  },
]);
