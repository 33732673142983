import Lottie from "lottie-react";
import React from "react";
import movingLottie from "../../../assets/lottie/moving_lottie.json";
import Flex from "./Flex";

interface LoaderProps {
  fullScreen?: boolean;
}

function Loader({ fullScreen = true }: LoaderProps) {
  return fullScreen ? (
    <Flex
      position="fixed"
      top={0}
      left={0}
      bottom={0}
      right={0}
      alignItems="center"
      justifyContent="center"
      backgroundColor="rgba(0,0,0,0.3)"
    >
      <Lottie animationData={movingLottie} loop autoPlay />
    </Flex>
  ) : (
    <Flex flex={1} alignItems="center" justifyContent="center">
      <Lottie
        animationData={movingLottie}
        loop
        autoPlay
        style={{ width: 150, height: 150 }}
        height="100%"
      />
    </Flex>
  );
}

export default Loader;
