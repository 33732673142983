import dayjs from "dayjs";

export const MIN_SCREEN_WIDTH: { [key: string]: number } = {
  DESKTOP: 900,
};

export const TOP_NAVIGATION_HEIGHT = 76;

export const MOBILE_TOP_BAR_HEIGHT = 64;

export const ALT_TEXT =
  "라보그라운드, laboground, 파트너십 관리 솔루션, 비즈니스 파트너십 관리, 협업 관리 도구, 파트너 관계 관리(PRM), 파트너 네트워크 관리, 비즈니스 파트너 프로그램, 파트너 포털 소프트웨어, 채널 파트너 관리, 파트너 성과 관리, 파트너 계약, MOU, 콜라보레이션, 스몰브랜드, 협력사 관리, 제휴마케팅, 자동화 솔루션, Partnership management solution, Business partnership tool, Partner relationship management, Partner collaboration platform, Channel partner management, Best partnership management software for businesses, How to manage business partnerships effectively, Channel partner performance tracking tool, Cloud-based partnership management solution, Partner onboarding and relationship management, Partner program automation, Vendor management system, Partner revenue optimization, Strategic partner collaboration, Partner contract automation";
export const SNS_CHANNELS = {
  YOUTUBE: "YOUTUBE",
  INSTAGRAM: "INSTAGRAM",
  FACEBOOK: "FACEBOOK",
  TIKTOK: "TIKTOK",
};

export const VIDEO_RULES = {
  youtube: {
    maxRatio: 1,
    minRatio: 9 / 16,
    minDuration: 0,
    maxDuration: 60,
  },
  facebook: {
    maxRatio: 16 / 9,
    minRatio: 9 / 16,
    minDuration: 0,
    maxDuration: 240 * 60,
  },
  instagram: {
    maxRatio: 9 / 16,
    minRatio: 9 / 16,
    minDuration: 3,
    maxDuration: 90,
  },
  tiktok: {
    maxRatio: 9 / 16,
    minRatio: 9 / 16,
    minDuration: 15,
    maxDuration: 3 * 60,
  },
};

export const DEFAULT_DATE_OF_BIRTH = dayjs()
  .subtract(18, "year")
  .format("YYYY-MM-DD");

export const PROFILE_TYPE = {
  BUSINESS: "BUSINESS",
  CREATOR: "CREATOR",
};

export const IMAGE_COMPRESSION_OPTIONS = {
  maxSizeMB: 0.5,
};
