import { InMemoryCache } from "@apollo/client";
import customRelayStylePagination from "./customRelayStylePagination";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getProfilePicks: customRelayStylePagination([
          "profileId",
          "query",
          "categoryIds",
          "countryIds",
          "stateIds",
          "collaborationTypeIds",
        ]),

        getActionsByProfileId: customRelayStylePagination([
          "profileId",
          "query",
          "defined",
          "activated",
        ]),
      },
    },
  },
});

export const setDefaultCache = (cache: any) => {
  // console.info("set default cache:", cache);
};

setDefaultCache(cache);

export default cache;
